
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import { UserFormData } from "@/services/auth/auth-service";
import { sleep } from "@/utils";
import * as types from "@/store/types";
import { NavigationFailureType, isNavigationFailure } from "vue-router";

@Component({
    methods: {
        ...mapActions({
            authLogin: types.AUTH_LOGIN
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER,
            services: types.SERVICIOS,
            idioma: types.IDIOMA
        })
    }
})
export default class SigninForm extends Vue {
    authLogin!: (userData: UserFormData) => void;
    services?: string[];
    userIdentifier?: number;
    idioma?: string;
    alerta = false;
    Talerta: string[] = ["-1", ""];

    formData: UserFormData = {
        email: "",
        password: ""
    };
    loading = false;

    errorData = {
        name: ("" as string) || null,
        message: ("" as string) || null
    };

    async created() {
        try {
            const serverResponse = await this.$api.checkServer<rdsa>();
            if (serverResponse.ok) {
                this.alerta = true;
                this.Talerta = serverResponse.data;
            }
        } catch (error) {
            this.alerta = true;
            this.Talerta = ["0"];
        }
    }

    async login() {
        this.loading = true;

        try {
            await this.authLogin(this.formData);
            await sleep(500);
            this.goTo();
        } catch (error) {
            this.errorData.name = error.name || "Error";
            this.errorData.message = error.message || "Error";
        } finally {
            this.loading = false;
        }
    }

    async goTo() {
        if (this.idioma) {
            this.$root.$i18n.locale = this.idioma;
        }
        localStorage.clear();
        if (Vue.prototype.$user.get().role == "client") {
            if (this.services?.includes("3")) {
                await this.$router.push({ path: "/installation" }).catch(e => {
                    if (
                        !isNavigationFailure(
                            e,
                            NavigationFailureType.redirected
                        )
                    ) {
                        Promise.reject(e);
                    } else if (
                        isNavigationFailure(e, NavigationFailureType.aborted)
                    ) {
                        this.errorData.name = "Error";
                        this.errorData.message = e.name;
                    }
                });
            } else {
                await this.$router.push({ path: "/resumen" }).catch(e => {
                    if (
                        !isNavigationFailure(
                            e,
                            NavigationFailureType.redirected
                        )
                    ) {
                        Promise.reject(e);
                    } else if (
                        isNavigationFailure(e, NavigationFailureType.aborted)
                    ) {
                        this.errorData.name = "Error";
                        this.errorData.message = e.name;
                    }
                });
            }
        } else if (Vue.prototype.$user.get().role == "admin") {
            await this.$router.push({ path: "/energy" }).catch(e => {
                if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
                    Promise.reject(e);
                } else if (
                    isNavigationFailure(e, NavigationFailureType.aborted)
                ) {
                    this.errorData.name = "Error";
                    this.errorData.message = e.name;
                }
            });
        }
    }
}
